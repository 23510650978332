/*
 * loginReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 */
// import { LoginActions } from '../actions/userAction';

// // The initial state of the App


// reducers/authReducer.js
import * as actionTypes from "../types/userTypes";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null, // Load user data from localStorage if it exists
  loading: false,
  error: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      console.log(action.type, "qwq")
      return { ...state, loading: true, error: null };
    case actionTypes.LOGIN_SUCCESS:
      return { ...state,
        user: action.payload,
        error: null, };
    case actionTypes.LOGIN_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default loginReducer;
// import { INCREMENT, DECREMENT } from '../types/userTypes';

// const initialState = {
//   count: 0,
// };

// const counterReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case INCREMENT:
//       return { ...state, count: state.count + 1 };
//     case DECREMENT:
//       return { ...state, count: state.count - 1 };
//     default:
//       return state;
//   }
// };

// export default counterReducer;

