import React, { useEffect, useState, useRef } from "react";
import Pencil from "../components/image/pencil.svg";
import "./Dashboard.css";
import Navbar from "../components/Navbar";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchBooking, fetchBookingFailure, fetchBookingSuccess, fetchEmployeeDetails, fetchEmployeeDetailsFailure, fetchEmployeeDetailsSuccess, fetchSlot, fetchSlotFailure, fetchSlotSuccess } from "../redux/actions/userAction";
import add from "../components/image/add.svg";
import { ENDPOINT } from "../network/Urls";
import { Link, useNavigate } from 'react-router-dom';
import { useData } from './DataContext';
import Refresh from '../components/image/Group.svg'


const timeSlots = [
  '02:00 PM - 03:00 PM',
  '03:00 PM - 04:00 PM',
  '04:00 PM - 05:00 PM',
  '05:00 PM - 06:00 PM',
  '06:00 PM - 07:00 PM',
  '07:00 PM - 08:00 PM',
  '08:00 PM - 09:00 PM',
  '09:00 PM - 10:00 PM',
  '10:00 PM - 11:00 PM',
  '11:00 PM - 12:00 AM',
  '12:00 AM - 01:00 AM',
  '01:00 AM - 02:00 AM',
  '02:00 AM - 03:00 AM',
];
function Table(props) {
  const dispatch = useDispatch();

  const { data, slotdata, user, userData } = props

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];

  const [date, setDate] = useState(formattedDate);
  const [slotid, setSlotsid] = useState('0')
  const storedBAddress = localStorage.getItem("bAddress");


  const storedUserData = JSON.parse(localStorage.getItem("current-user"));
  console.log("newUser", storedUserData);
  const [bookingURL, setBookingURL] = useState("");
  const [currency, setCurrency] = useState("");

  const [requestData, setRequestData] = useState({
    date: formattedDate,
    bAddress: storedBAddress
  });

  useEffect(() => {
    if (storedUserData === null) {
      if (user && user.Employee && user.Employee.businessDetails) {
        setBookingURL(user.Employee.businessDetails[0]?.bAddress?.bookingURL);
        setCurrency(user.Employee.businessDetails[0]?.bAddress?.currrency)
      }
    } else {
      setBookingURL(storedUserData.bAddress?.bookingURL);
      setCurrency(storedUserData.bAddress?.currrency)
    }
  }, [user, storedUserData]);

  useEffect(() => {
    if (bookingURL) {
      dispatch(fetchBooking(requestData));
      dispatch(fetchSlot(requestData));
    }
  }, [bookingURL, requestData, dispatch]);

  useEffect(() => {
    setRequestData((prevRequestData) => ({
      ...prevRequestData,
      date: date,
      bAddress: storedBAddress,
      slot: slotid !== '0' ? slotid : undefined,
    }));
  }, [date, slotid, storedBAddress]);

  // let bookingURL = ""; // Declare bookingURL variable outside the if statement

  // if (user && user.Employee && user.Employee.businessDetails) {
  //   bookingURL = user.Employee.businessDetails[0]?.bAddress?.bookingURL;
  //   console.log("Company Name URL:", bookingURL, userData);
  // }

  // if (storedUserData === null) {
  //   bookingURL = user.Employee.businessDetails[0]?.bAddress?.bookingURL;
  // } else {

  //   bookingURL = storedUserData.bAddress?.bookingURL;
  // }


  const bookingsArray = data && data.data && data.data.Bookings ? data.data.Bookings : [];
  const slotArray = slotdata && slotdata.data && slotdata.data.Slots ? [...slotdata.data.Slots, { _id: 0, startTimeInString: "     All Slots" }] : [];


  console.log("121", bookingsArray)

  // const storedSlot = localStorage.getItem("slot");


  function handleSlotClick(slot, id) {
    // alert(`Selected slot: ${slot}`);
    setSlotsid(id)
    {
      id == '0' ? setRequestData({
        date: date,
        bAddress: storedBAddress,
      }) : setRequestData({
        date: date,
        bAddress: storedBAddress,
        slot: id
      });
    }
    closeModal()
    setSlots(slot)
  }
  const Modal = ({ isOpen }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-container">
          {slotArray.map((modalItem) => (
            <div key={modalItem._id}>
              <div
                className="modal-slot-content"
                onClick={() =>
                  handleSlotClick(modalItem.startTimeInString, modalItem._id)
                }
              >
                {modalItem.startTimeInString}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const getStatusStyle = (status) => {
    switch (status) {
      case "COMPLETED":
        return {
          backgroundColor: "#ED1B1B2E",
          height: 22,
          width: "auto",
          borderRadius: 11,
          textAlign: 'center',
          color: "#ED1B1B",
          borderColor: "#ED1B1B",
          borderWidth: 0.5,
        };
      case "NOTPRESENT":
        return {
          backgroundColor: "#7E7E7E39",
          height: 22,
          width: "auto",
          borderRadius: 11,
          textAlign: 'center',
          color: "#7E7E7E",
          borderColor: "#7E7E7E",
          borderWidth: "2px",
        };
      case "WAITING":
        return {
          backgroundColor: "#C78E0056",
          height: 22,
          width: "auto",
          borderRadius: 11,
          textAlign: 'center',
          color: "#C78E00",
          borderColor: "#C78E00",
          borderWidth: 0.5,
        };
      case "BOOKED":
        return {
          backgroundColor: "#C700A72A",
          height: 22,
          width: "auto",
          borderRadius: 11,
          textAlign: 'center',
          color: "#C700A7",
          borderColor: "#C700A7",
          borderWidth: 0.5,
        };
      case "ONGOING":
        return {
          backgroundColor: "#08BEBC45",
          height: 22,
          width: "auto",
          borderRadius: 11,
          textAlign: 'center',
          color: "#08BEBC",
          borderColor: "#08BEBC",
          borderWidth: 0.5,
        };
      default:
        return {
          backgroundColor: 'white',
          height: 22,
          width: "auto",
          borderRadius: 11,
          textAlign: 'center',
          color: 'white',
          borderColor: 'white',
          borderWidth: 0.5,
        };
    }
  };

  const [slot, setSlots] = useState()

  const onDateChange = (event) => {
    setDate(event.target.value);
    {
      slotid == '0' ? setRequestData({
        date: event.target.value,
        bAddress: storedBAddress,
      }) : setRequestData({
        date: event.target.value,
        bAddress: storedBAddress,
        slot: slotid
      });
    }

  };
  const mystyle = {
    color: "black",
    backgroundColor: "lightgrey",
    padding: "2rem",
    width: "700px",
  };
  const dateStyle = {
    backgroundColor: "  rgba(0, 150, 136, 0.260216)",
    borderRadius: "22.5px",
    height: "30px",
    width: "170px",
    fontSize: "20px",
    fontWeight: "700",
    color: "#009688",
    border: "1px solid transparent",
    padding: "7px"

  };

  const [dropdownOpen, setDropdownOpen] = useState(new Array(bookingsArray.length).fill(false));
  const closeDropdown = () => {
    setDropdownOpen([]);

  };

  const formbuttonn = (shortId) => {

    setDropdownOpen([]);
    // Linking.openURL(BASE_URL + "/i/" + route.params.shortId);
    const fullURL = ENDPOINT + '/i/' + shortId;
    window.open(fullURL, '_blank');



  }
  const PaymentReceived = (shortId) => {

    setDropdownOpen([]);
    // Linking.openURL(BASE_URL + "/i/" + route.params.shortId);
    const fullURL = ENDPOINT + '/i/' + shortId;
    window.open(fullURL, '_blank');



  }

  const toggleDropdown = (index) => {
    const newIsDropdownOpenArray = [...dropdownOpen];
    newIsDropdownOpenArray[index] = !newIsDropdownOpenArray[index];
    setDropdownOpen(newIsDropdownOpenArray);
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  // useEffect(() => {

  //   dispatch(fetchBooking(requestData));
  //   dispatch(fetchSlot(requestData));


  // }, [dispatch, requestData]);


  // const handleFetchBooking = () => {
  //   dispatch(fetchBooking(requestData));
  // };

  const navigate = useNavigate();
  const { setData } = useData();
  const handleLinkClick = (data) => {


    setData(data);
    navigate(`/invoiceCreate/${data._id}`);
  };


  return (
    <>
      <div className="main-container">
        <div className="header">


          <div className="row header-left" style={{ display: "flex", justifyContent: "space-between" }}>
            <div class="col" style={{ display: 'flex' }}>
              <div className="data-date">
                <input
                  type="date"
                  style={dateStyle}
                  value={date}
                  onChange={onDateChange}
                />
              </div>

              <div className="data-slot" onClick={openModal}>
                <span style={{ padding: "10px" }}>{slot ? slot : "All Slots"}</span>
              </div>
            </div>


            <div class="col">
              <div className="refresh-container" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <img src={Refresh} alt="refresh" onClick={() => window.location.reload()} style={{ width: '118px', height: '30px', alignSelf: 'center', display: 'flex', cursor: 'pointer', right: '-50px', position: 'relative' }} />
              </div>
            </div>
          </div>


        </div>

        <div className="table-view">
          <div className="data-box">
            {bookingsArray.length > 0 ?
              bookingsArray.map((data, index) => (
                <div className="d-flex align-items-start" style={{ justifyContent: 'center' }} key={index}>
                  <div className="row mt-3" style={{ width: "100%", gap: "0px", padding: '6px' }}>
                    <div className="col">
                      <div className="d-flex flex-column align-items-start">
                        <div className="table-names">
                          <span>{data?.cName}</span>
                          <span>{data?.cPhoneNumber}</span>

                        </div>
                        <div className="custom-date">
                          <span>{data?.date} </span>
                          <span> {data?.slotName}</span>

                          {
                            data?.paymentRecived && 
                              (<span style={{
                                width: '134px',
                                height: '32px',
                                top: '419px',
                                left: '516px',
                                gap: '0px',
                                opacity: '0px',
                                fontFamily: 'Montserrat',
                                fontSize: '19px',
                                fontWeight: '700',
                                lineHeight: '31.69px',
                                textAlign: 'left',
                                color: '#08BEBC',
                                marginLeft: '14px'
                              }}>
                                {currency}{data?.paymentRecived}
                              </span>) 
                          }

                        </div>
                      </div>
                    </div>


                    <div className="status-container" style={{ ...getStatusStyle(data.status), alignSelf: "center" }}>
                      <span className="status-text">{data.status}</span>
                    </div>



                    <div className="col col-lg-1" style={{ alignSelf: "center", textAlign: 'center' }}>
                      <div className="dropdown-container">
                        <button className="pencil" onClick={() => toggleDropdown(index)}>
                          <img src={Pencil} alt="pencil" className="pencil-button" />
                        </button>
                        {dropdownOpen[index] && (
                          <div className="overlay">
                            <div className="dropdown-content">
                              <Link to={`https://www.queuemate.com/${data.shortId}`} target="_blank" onClick={closeDropdown}>View Details</Link>
                              <a href="#" onClick={() => formbuttonn(data.shortId)}>Download Invoice</a>
                              {/* <a href="#" onClick={() => handleLinkClick(data)}>invoice Details</a> */}

                            </div>
                          </div>
                        )}

                      </div>

                    </div>
                    <hr></hr>
                  </div>
                </div>
              )) : <div style={{ justifyContent: 'center', display: "flex", height: "250px", alignItems: "center" }}>
                <h2>No booking found</h2>
              </div>}
          </div>
        </div>

        <Modal isOpen={isModalOpen} closeModal={closeModal}>

        </Modal >
        <Link to={bookingURL} target="_blank" rel="noreferrer">
          <img
            className="floating-image"
            src={add}
            alt="Floating"
          />
        </Link>
      </div >
    </>
  );
}

const mapStateToProps = (state) => {
  console.log(state, "stat")
  return {
    data: state.fetchBookingReducer,
    slotdata: state.fetchSlotReducer,
    EmployeeDetails: state.EmployeeDetailsReducer,
    user: state.login.user,
    userData: state.userReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBookingSuccess: (value) => dispatch(fetchBookingSuccess(value)),
    fetchBookingFailure: (value) => dispatch(fetchBookingFailure(value)),
    fetchSlotSuccess: (value) => dispatch(fetchSlotSuccess(value)),
    fetchSlotFailure: (value) => dispatch(fetchSlotFailure(value)),
    fetchEmployeeDetailsSuccess: (value) => dispatch(fetchEmployeeDetailsSuccess(value)),
    fetchEmployeeDetailsFailure: (value) => dispatch(fetchEmployeeDetailsFailure(value)),
  };
};
// const Modal = ({ isOpen, closeModal, children }) => {
//   if (!isOpen) return null;

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">

//         {children}
//       </div>
//     </div>
//   );
// };

export default connect(mapStateToProps, mapDispatchToProps)(Table);