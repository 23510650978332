import axios from "axios";
import { Urls, ENDPOINT } from "../network/Urls";

export const getUserDetails = async () => {
  try {
    const res = await axios({
      method: 'GET',
    
      url: `${ENDPOINT}/${Urls.getEmplyeeDetail}`, // Fix the typo here
    });
    return res;
  } catch (error) {
    // Handle errors if needed
    console.error("Error fetching user details:", error);
    throw error; // You might want to rethrow the error or handle it according to your needs
  }
};
