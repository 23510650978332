import React from 'react'
import Navbar from './Navbar'

function Reports() {

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
    };
    return (
        <>
            <Navbar />
            <div style={containerStyle}>
                Work In Progress
            </div>
        </>
    )
}

export default Reports