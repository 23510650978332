import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import FormGroup from '@mui/material/FormGroup';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import "./Dashboard.css";
// import Profile from "../components/image/profile.svg";
import Sidebar from "./sideBar";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { useState } from "react";
import UserSwitchPopup from "./UserSwitchPopup";

const Navbar = (props) => {
  // const [auth, setAuth] = React.useState(true);
  const { user, userData } = props;
  if (user && user.Employee && user.Employee.businessDetails) {
    const companyName = user.Employee.businessDetails[0]?.company?.companyName;
    console.log("qwerty", userData);
  }

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isUserSwitchPopupOpen, setUserSwitchPopupOpen] = useState(false); // State for managing popup visibility


  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserSwitchClick = () => {
    setUserSwitchPopupOpen(true);
    handleClose(); // Close the menu when switching users
  };

  const handleUserSwitchPopupClose = () => {
    setUserSwitchPopupOpen(false);
  };

  const storedUserData = JSON.parse(localStorage.getItem("current-user"));

  let companyName = "Company Name";
  let companyLogo = "";
  console.log('zxzxzx', companyName, companyLogo)
  if (storedUserData === null) {
    companyName = user.Employee.businessDetails[0].bAddress.name;
    companyLogo = user.Employee.businessDetails[0].bAddress.logo;
  } else {

    companyName = storedUserData.bAddress.name;
    companyLogo = storedUserData.bAddress.logo;
  }


  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <AppBar sx={{ background: "#08BEBC" }} position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleSidebarToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="center" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>

          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Typography
                variant="h6"
                component="center"
                sx={{ flexGrow: 1 }}
                className="data-name"
              >
                {companyName}
              </Typography>
              <img src={companyLogo} alt="profile" className="data-image" />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ top: 40, left: -7 }}
            >
              <MenuItem onClick={handleUserSwitchClick}>Switch Account</MenuItem>
              {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
              <MenuItem onClick={() => {
                localStorage.removeItem("user-info"); 
                localStorage.removeItem("bAddress");
                localStorage.removeItem("empId");
                localStorage.removeItem("current-user");
                localStorage.removeItem("user");
                localStorage.removeItem("bid"); navigate("/")
              }}>Log Out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
      {isUserSwitchPopupOpen && <UserSwitchPopup onClose={handleUserSwitchPopupClose} />}
    </Box>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.login.user, // Access user data from Redux store
    userData: state.userReducer.userData,
  };
};

// Connect component to Redux store
export default connect(mapStateToProps)(Navbar);