// import * as actionTypes from "../types/userTypes";
// import axios from "axios";
// import { ENDPOINT, Urls } from "../../network/Urls";
// import { createActionTypes } from 'redux-actions';


// export const VERIFY_LOGIN = 'VERIFY_LOGIN';
// export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// export const LOGIN_FAIL = 'LOGIN_FAIL';
// export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';

// export const fetching = (value) => (dispatch) => {
//   dispatch({ type: actionTypes.FETCHING, payload: value });
// };

// export const setError = (value) => (dispatch) => {
//   dispatch({ type: actionTypes.SET_ERROR, payload: value });
// };





// export const verifyLogin = (obj) => ({
//   type: VERIFY_LOGIN.VERIFY_LOGIN,
//   payload: {
//     ...obj,
//   },
// });

// export const loginSuccess = (obj) => ({
//   type: LOGIN_SUCCESS.LOGIN_SUCCESS,
//   payload: {
//     ...obj,
//   },
// });

// export const loginFailure = (obj) => ({
//   type: LOGIN_FAIL.LOGIN_FAIL,
//   payload: {
//     ...obj,
//   },
// });

// export const resetErrorForLogin = (obj) => ({
//   type: RESET_LOGIN_ERROR.RESET_LOGIN_ERROR,
//   payload: {
//     ...obj,
//   },
// });

// export const loginData = (payload) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     console.log("payload", payload);
//     dispatch(fetching(true));
//     axios
//       .post(`${ENDPOINT}/${Urls.login}`, payload)
//       .then((response) => {
//         console.log("response", response);
//         const { status, message } = response?.data;
//         dispatch(fetching(false));
//         if (status && status == 200) {
//           loginSuccess({
//             message: response.response,
//           })
//         } else {
//           loginFailure({
//             message: response.message,
//           })
//           dispatch(setError(message));
//         }
//         resolve(response.data);
//       })
//       .catch((error) => {
//         console.log("Error", error);
//         reject(error);
//         dispatch(fetching(false));
//         loginFailure({
//           message: 'Something went wrong',
//         })
//       });
//   });
// };

// function* verifyLogin(dObj) {
//   try {
//     const url = '/login';
//     const res = yield call(apiResource.post, url, dObj.payload);

//     if (res.status === 1) {
//       yield put(
//         loginSuccess({
//           message: res.response,
//         }),
//       );
//     } else {
//       yield put(
//         loginFailure({
//           message: res.message,
//         }),
//       );
//     }
//   } catch (e) {
//     yield put(
//       loginFailure({
//         message: 'Something went wrong',
//       }),
//     );
//   }
// }


import axios from "axios";
import { ENDPOINT, Urls } from "../../network/Urls";
import * as actionTypes from "../types/userTypes";
import { toast } from 'react-toastify';

export const loginRequest = () => ({
  type: actionTypes.LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: actionTypes.LOGIN_FAILURE,
  payload: error,
});

export const login = (payload) => (dispatch) => {
  dispatch(loginRequest());

  axios
    .post(`${ENDPOINT}/${Urls.login}`, payload)
    .then((response) => {
      console.log("response", response.data.Employee.businessDetails[0].address);
      if (response.data.Message === "Success") {
        const user = response.data;
        localStorage.setItem("user-info", response.data.token);
        localStorage.setItem("empId", response.data.Employee.businessDetails[0].employee);
        localStorage.setItem("bAddress", response.data.Employee.businessDetails[0].address);
        localStorage.setItem("bid", response.data.Employee.businessDetails[0].business);
        localStorage.setItem("user", JSON.stringify(user));
        console.log(response.data.Employee.businessDetails[0],"kkkk")
        toast.success(response.data.Message, { autoClose: 3000 });
        dispatch(loginSuccess(user));
        setTimeout(() => {
          window.location.replace("/dashboard")
        }, 1000);
      } else {
        toast.error(response.data.Message);
        dispatch(loginFailure("Login failed: " + response.data.Message));
      }
    })
    .catch((error) => {
      console.error("Login error:", error);
      toast.error("Login failed: Phone Number or Password entered is incorrect", { autoClose: 3000 });
      dispatch(loginFailure("Login failed: " + error.message));
    });
};

export const setCurrentUser = (userData) => {
  return {
    type: actionTypes.SET_CURRENT_USER,
    payload: userData
  };
};

export const resetErrorForLogin = () => ({
  type: actionTypes.RESET_LOGIN_ERROR,
});




export const fetchBookingRequest = () => ({
  type: actionTypes.FETCH_BOOKING_REQUEST,
});

export const fetchBookingSuccess = (data) => ({
  type: actionTypes.FETCH_BOOKING_SUCCESS,
  payload: data,
});

export const fetchBookingFailure = (error) => ({
  type: actionTypes.FETCH_BOOKING_FAILURE,
  payload: error,
});

export const fetchBooking = (requestData) => (dispatch) => {
  dispatch(fetchBookingRequest());
  let token = localStorage.getItem("user-info");
  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");

  // Make the API request
  fetch(`${ENDPOINT}/${Urls.getParticularSlotBooking}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestData),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle a successful response
      dispatch(fetchBookingSuccess(data));
      console.log("jjbjbj",data)
    })
    .catch((error) => {
      // Handle errors
      dispatch(fetchBookingFailure(error));
    });
};

export const fetchSlotRequest = () => ({
  type: actionTypes.FETCH_SLOT_REQUEST,
});

export const fetchSlotSuccess = (data) => ({
  type: actionTypes.FETCH_SLOT_SUCCESS,
  payload: data,
});

export const fetchSlotFailure = (error) => ({
  type: actionTypes.FETCH_SLOT_FAILURE,
  payload: error,
});

export const fetchSlot = (requestData) => (dispatch) => {
  dispatch(fetchBookingRequest());
  let token = localStorage.getItem("user-info");

  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");

  // Make the API request
  fetch(`${ENDPOINT}/${Urls.getSlotDetails}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestData),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle a successful response
      dispatch(fetchSlotSuccess(data));
      console.log("slot",data)
    })
    .catch((error) => {
      // Handle errors
      dispatch(fetchSlotFailure(error));
    });
};








export const fetchEmployeeRequest = () => ({
  type: actionTypes.FETCH_EMPLOYEE_REQUEST,
});

export const fetchEmployeeSuccess = (data) => ({
  type: actionTypes.FETCH_EMPLOYEE_SUCCESS,
  payload: data,
});

export const fetchEmployeeFailure = (error) => ({
  type: actionTypes.FETCH_EMPLOYEE_FAILURE,
  payload: error,
});

export const fetchEmployee = () => (dispatch) => {
  dispatch(fetchEmployeeRequest());
  let token = localStorage.getItem("user-info");
  let id = localStorage.getItem("bAddress");
  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
  // Make the API request
  fetch(`${ENDPOINT}/${Urls.getEmplyeelist}/${id}`, {
    method: "GET",
    headers: myHeaders,
  
    redirect: "follow",
    
  })
    .then((response) => response.json())
    .then((data) => {
    
      dispatch(fetchEmployeeSuccess(data));
      console.log("employee",data)
    })
    .catch((error) => {
   
      dispatch(fetchEmployeeFailure(error));
    });
};


export const fetchEditPermissionsRequest = () => ({
  type: actionTypes.FETCH_EMPLOYEE_REQUEST,
});

export const fetchEditPermissionsSuccess = (data) => ({
  type: actionTypes.FETCH_EMPLOYEE_SUCCESS,
  payload: data,
});

export const fetchEditPermissionsFailure = (error) => ({
  type: actionTypes.FETCH_EMPLOYEE_FAILURE,
  payload: error,
});

export const fetchEditPermissions = (requestData) => (dispatch) => {
  dispatch(fetchEditPermissionsRequest());
  let token = localStorage.getItem("user-info");

  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
  // Make the API request
  fetch(`${ENDPOINT}/${Urls.editPermissions}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestData),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle a successful response
      dispatch(fetchEditPermissionsSuccess(data));
      console.log("employee",data)
    })
    .catch((error) => {
      // Handle errors
      dispatch(fetchEditPermissionsFailure(error));
    });
};


export const fetchActivateDeactivateEmployeeRequest = () => ({
  type: actionTypes.FETCH_ACTIVATE_REQUEST,
});

export const fetchActivateDeactivateEmployeeSuccess = (data) => ({
  type: actionTypes.FETCH_ACTIVATE_SUCCESS,
  payload: data,
});

export const fetchActivateDeactivateEmployeeFailure = (error) => ({
  type: actionTypes.FETCH_ACTIVATE_FAILURE,
  payload: error,
});

export const fetchActivateDeactivateEmployee = (requestData) => (dispatch) => {
  dispatch(fetchActivateDeactivateEmployeeRequest());
  let token = localStorage.getItem("user-info");

  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
  // Make the API request
  fetch(`${ENDPOINT}/${Urls.activateDeactivateEmployee}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestData),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle a successful response
      dispatch(fetchActivateDeactivateEmployeeSuccess(data));
      console.log("employee",data)
    })
    .catch((error) => {
      // Handle errors
      dispatch(fetchActivateDeactivateEmployeeFailure(error));
    });
};



export const fetchCreateEmployeeRequest = () => ({
  type: actionTypes.FETCH_CREATEEMPLOYEE_REQUEST,
});

export const fetchCreateEmployeeSuccess = (data) => ({
  type: actionTypes.FETCH_CREATEEMPLOYEE_SUCCESS,
  payload: data,
});

export const fetchCreateEmployeeFailure = (error) => ({
  type: actionTypes.FETCH_CREATEEMPLOYEE_FAILURE,
  payload: error,
});

export const fetchCreateEmployee = (requestData) => (dispatch) => {
  dispatch(fetchCreateEmployeeRequest());
  let token = localStorage.getItem("user-info");

  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");

  // Make the API request
  fetch(`${ENDPOINT}/${Urls.createEmployee}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestData),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle a successful response
      dispatch(fetchCreateEmployeeSuccess(data));
      console.log("employeeee",requestData)
    })
    .catch((error) => {
      // Handle errors
      dispatch(fetchCreateEmployeeFailure(error));
    });
};





export const fetchEditEmployeeRequest = () => ({
  type: actionTypes.FETCH_EMPLOYEE_EDIT_REQUEST,
});

export const fetchEditEmployeeSuccess = (data) => ({
  type: actionTypes.FETCH_EMPLOYEE_EDIT_SUCCESS,
  payload: data,
});

export const fetchEditEmployeeFailure = (error) => ({
  type: actionTypes.FETCH_EMPLOYEE_EDIT_FAILURE,
  payload: error,
});

export const fetchEditEmployee = (requestData) => (dispatch) => {
  dispatch(fetchEditEmployeeRequest());
  let token = localStorage.getItem("user-info");

  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
  // Make the API request
  fetch(`${ENDPOINT}/${Urls.editprofileEmployee}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestData),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle a successful response
      dispatch(fetchEditEmployeeSuccess(data));
      console.log("employee      edit ",data)
    })
    .catch((error) => {
      // Handle errors
      dispatch(fetchEditEmployeeFailure(error));
    });
};


export const fetchUpdatePasswordRequest = () => ({
  type: actionTypes.FETCH_UPDATE_PASSWORD_REQUEST,
});

export const fetchUpdatePasswordSuccess = (data) => ({
  type: actionTypes.FETCH_UPDATE_PASSWORD_SUCCESS,
  payload: data,
});

export const fetchUpdatePasswordFailure = (error) => ({
  type: actionTypes.FETCH_UPDATE_PASSWORD_FAILURE,
  payload: error,
});

export const fetchUpdatePassword = (requestData) => (dispatch) => {
  dispatch(fetchUpdatePasswordRequest());
  let token = localStorage.getItem("user-info");

  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
  // Make the API request
  fetch(`${ENDPOINT}/${Urls.updatePassword}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestData),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle a successful response
      dispatch(fetchUpdatePasswordSuccess(data));
      console.log("      edit ",data)
    })
    .catch((error) => {
      // Handle errors
      dispatch(fetchUpdatePasswordFailure(error));
    });
};


export const fetchPayementHistoryRequest = () => ({
  type: actionTypes.FETCH_PAYEMENT_HISTORY_REQUEST,
});

export const fetchPayementHistorySuccess = (data) => ({
  type: actionTypes.FETCH_PAYEMENT_HISTORY_SUCCESS,
  payload: data,
});

export const fetchPayementHistoryFailure = (error) => ({
  type: actionTypes.FETCH_PAYEMENT_HISTORY_FAILURE,
  payload: error,
});

export const fetchPayementHistory= (requestData) => (dispatch) => {
  dispatch(fetchPayementHistoryRequest());
  let token = localStorage.getItem("user-info");

  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
  // Make the API request
  fetch(`${ENDPOINT}/${Urls.payementHistory}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestData),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle a successful response
      dispatch(fetchPayementHistorySuccess(data));
      console.log("employee",data)
    })
    .catch((error) => {
      // Handle errors
      dispatch(fetchPayementHistoryFailure(error));
    });
};


export const fetchEmployeeDetailsRequest = () => ({
  type: actionTypes.FETCH_EMPLOYEE_DETAILS_REQUEST,
});

export const fetchEmployeeDetailsSuccess = (data) => ({
  type: actionTypes.FETCH_EMPLOYEE_DETAILS_SUCCESS,
  payload: data,
});

export const fetchEmployeeDetailsFailure = (error) => ({
  type: actionTypes.FETCH_EMPLOYEE_DETAILS_FAILURE,
  payload: error,
});

export const fetchEmployeeDetails = (requestData) => (dispatch) => {
  dispatch(fetchEmployeeDetailsRequest());
  let token = localStorage.getItem("user-info");

  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
  // Make the API request

  fetch(`${ENDPOINT}/${Urls.getEmplyeeDetail}/${requestData}`, {
    method: "GET",
    headers: myHeaders,
  
    redirect: "follow",
    
  })
    .then((response) => response.json())
    .then((data) => {
    
      dispatch(fetchEmployeeDetailsSuccess(data));
      console.log("employee details",data)
    })
    .catch((error) => {
   
      dispatch(fetchEmployeeDetailsFailure(error));
    });
};