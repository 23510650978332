import React, { useEffect, useState } from 'react';
import './invoice.css';
import Navbar from './Navbar';
import { useLocation, useParams } from 'react-router-dom';
import { fetchEmployee, fetchEmployeeDetails, fetchEmployeeDetailsFailure, fetchEmployeeDetailsSuccess, fetchEmployeeFailure, fetchEmployeeSuccess, fetchPayementHistory, fetchPayementHistoryFailure, fetchPayementHistorySuccess } from '../redux/actions/userAction';
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ENDPOINT, Urls } from "../network/Urls";
import { useData } from './DataContext';


const InvoiceCreate = (props) => {
  const dispatch = useDispatch();
  const { payementHistory, EmployeeDetails } = props
  const { data } = useData();




  const employeedetails = EmployeeDetails.data
  const PayementHistory = payementHistory.data?.payments || [];
  const { shortId } = useParams();

  const [pObject, setPObject] = useState([])
  const [pObject1, setPObject1] = useState([])
  const [baseMoney, setBaseMoney] = useState('');
  const [permissions, setPermissions] = useState('')
  const [currency, setCurrency] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [bookingName, setBookingName] = useState('');
  const [slottId, setSlottId] = useState('');
  const [businessAddres, setBusinessAddres] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [amount, setAmount] = useState("")
  const [reason, setReason] = useState("")
  const [quantity, setQuantity] = useState("0")
  const [empId, setEmpId] = useState('');
  const [tax, setTax] = useState('')
  const [isPercent, setIsPercent] = useState('')
  const [tName, setTName] = useState('')
  const [pObjReason, setpobjReason] = useState('')
  const [pObjAmount, setpObjAount] = useState('')

  // const [form, setForm] = useState({
  //   reason: '',
  //   amount: '',
  //   quantity: ''
  // });
  const storedempId = localStorage.getItem("empId");


  useEffect(() => {
    // console.log('Fetching employee details...',employeedetails.Booking[0].baddress.currrency);
    Employee(shortId)
    //  dispatch(fetchEmployeeDetails(shortId));

    console.log('Fetching payment history...');
    const requestData1 = { bookingId: shortId };
    dispatch(fetchPayementHistory(requestData1));

    console.log('Setting state based on fetched data...');
    // employeedetails?.Booking && updateBookingDetails(employeedetails.Booking[0]);
    // employeedetails?.Booking && setBookingId(employeedetails.Booking[0]._id);
    // employeedetails?.Booking && setSlottId(employeedetails.Booking[0].slot._id);
    // employeedetails?.Booking && setCurrency(employeedetails.Booking[0].baddress.currrency);
    // employeedetails?.Booking && setBusinessId(employeedetails.Booking[0].baddress._id);
    // employeedetails?.Booking && setBusinessAddres(employeedetails.Booking[0].baddress.business);
    // employeedetails?.Booking && setEmpId(storedempId);
    // employeedetails?.Booking && setBookingName(employeedetails.Booking[0].cName);
    // employeedetails?.Booking && setPObject(employeedetails.Booking[0].baddress.paymentReasons);

  }, [dispatch, tName]);



  const updateBookingDetails = (booking) => {
    setBookingId(booking._id);
    setBusinessId(booking.baddress._id);
    setSlottId(booking.slot._id);
    setCurrency(booking.baddress.currrency);
    setBaseMoney(booking.baddress.baseMoney);
    setBusinessAddres(booking.baddress.business);
    setEmpId(storedempId);
    setBookingName(booking.cName);
    setPObject(booking.baddress.paymentReasons);
  };

  // ...

  // Call the method conditionally



  const addPayement = () => {

    let token = localStorage.getItem("user-info");

    // Define your headers and request data here
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("timeZone", "Asia/Calcutta");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
    // Make the API request
    const requestData3 = {
      bookingId: shortId
    }
    const requestData2 = {
      booking: bookingId,
      slot: slottId,
      amount: amount,
      reason: reason,
      currency: currency,
      qty: quantity,
      address: businessAddres,
      business: businessId,
      employee: empId,
      pObject: {
        reason: pObjReason,
        amount: pObjAmount,
        tax: tax,
        isPercent: isPercent,
        tName: tName,
      },
    };
    console.log(requestData2, "response")
    fetch(`${ENDPOINT}/${Urls.addPayment}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData2),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle a successful response
        alert(data.Message)
        dispatch(fetchEmployeeDetails(shortId));
        setAmount('')
        setQuantity('')
        setReason('')
        // updateBookingDetails(employeedetails.Booking[0]);
        dispatch(fetchPayementHistory(requestData3));
      })
      .catch((error) => {
        // Handle errors

      });
  };
  const handleCheckboxChange = (e) => {
    // Add your checkbox handling logic here
    console.log('Checkbox changed:', e.target.checked);
  };
  const Employee = (requestData) => {

    let token = localStorage.getItem("user-info");

    // Define your headers and request data here
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("timeZone", "Asia/Calcutta");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
    // Make the API request

    fetch(`${ENDPOINT}/${Urls.getBookingDetail}/${requestData}`, {
      method: "GET",
      headers: myHeaders,

      redirect: "follow",

    })
      .then((response) => response.json())
      .then((data) => {

        updateBookingDetails(data.Booking[0]);
        console.log("employee details 222", data.Booking[0])
      })
      .catch((error) => {


      });
  };
  const SendSMS = () => {

    let token = localStorage.getItem("user-info");

    // Define your headers and request data here
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("timeZone", "Asia/Calcutta");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
    // Make the API request
    const requestData2 = {
      bookingId: shortId
    }
    fetch(`${ENDPOINT}/${Urls.SMSInvoice}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData2),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle a successful response
        alert(data.Message)

        dispatch(fetchPayementHistory(requestData2));
      })
      .catch((error) => {
        // Handle errors

      });
  };


  const formbuttonn = () => {


    // Linking.openURL(BASE_URL + "/i/" + route.params.shortId);
    const fullURL = ENDPOINT + '/i/' + employeedetails.Booking[0].shortId;
    window.open(fullURL, '_blank');



  }
  const DeletePayement = (requestData) => {

    let token = localStorage.getItem("user-info");

    // Define your headers and request data here
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("timeZone", "Asia/Calcutta");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
    // Make the API request
    const requestData2 = {
      bookingId: shortId
    }
    fetch(`${ENDPOINT}/${Urls.removePaymentId}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle a successful response
        // alert(data.Message)

        dispatch(fetchPayementHistory(requestData2));
      })
      .catch((error) => {
        // Handle errors

      });
  };

  const handleChange = (e, selectedVal) => {
    const { name, value } = e.target;
    console.log(selectedVal)
    setPObject1(selectedVal)
    setAmount(selectedVal.amount)
    setReason(selectedVal.reason)
    setTName(selectedVal.tName)
    setIsPercent(selectedVal.isPercent)
    setpObjAount(selectedVal.amount)
    setTax(selectedVal.tax)
    setpobjReason(selectedVal.reason)
    // Show alert when an option is selected
    // if (name === 'reason') {
    //   alert(`You selected: ${value}`);
    // }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const currentDate = new Date();
    console.log("emp:", employeedetails)
    addPayement()

  };

  const handleDelete = (id) => {
    // Show an alert to confirm deletion
    const userConfirmed = window.confirm('Do you want to delete this payment?');
    const requestData1 = {
      paymentId: id
    }

    // Check if the user confirmed
    if (userConfirmed) {
      // Call the DeletePayement method
      DeletePayement(requestData1);


    } else {
      // User canceled, do nothing or handle it as needed
      console.log('Deletion canceled by user');
    }
  };


  return (
    <>
      <Navbar title={`Invoice Creations ${bookingName}`} />

      <div className="invoice-container">

        <h2 style={{ top: "20px", position: "relative", alignSelf: 'center' }}>Payment Received</h2>
        <form className="invoice-form" onSubmit={handleSubmit}>
  <div className="invoice-group" style={{ width: "100%" }}>
    <label htmlFor="reason">
      Reason:
      <select id="reason" name="reason" value={reason} onChange={(e) => handleChange(e, pObject.find(val => val.reason === e.target.value))}>
        <option value="">Select a reason</option>
        {pObject.map((val, index) => (
          <option key={index} value={val.reason}>
            {val.reason}
          </option>
        ))}
      </select>
    </label>
  </div>

  <div className="invoice-group">
    <label htmlFor="amount">
      Amount:
      <input
        type="text"
        id="amount"
        name="amount"
        value={amount}
        placeholder="Amount"
        onChange={(e) => setAmount(e.target.value)}
      />
    </label>
  </div>

  <div className="todo-item">
    <div className="quantity-controls">
      <button className="subtract" type="button" onClick={() => setQuantity(quantity - 1)}>
        -
      </button>
      <label htmlFor="quantity" className="subtract-label">
        Quantity:
        <input
          type="number"
          id="quantity"
          name="quantity"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </label>
      <button className="add" type="button" onClick={() => setQuantity((prevQuantity) => +prevQuantity + 1)}>
        +
      </button>
    </div>
  </div>

  <button type="submit">Save</button>
</form>



        <h2>History</h2>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Reason</th>
              <th>Amount</th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {PayementHistory && PayementHistory.map((val, index) => (
              <tr key={index}>
                <td>{moment((val.createdTime) * 1000).format("DD MMM.yyyy")}</td>
                <td>{moment((val.createdTime) * 1000).format(" hh:mm a")}</td>

                <td>{val.reason}</td>
                <td>{val.currency}{val.netAmount}</td>

                <td>
                  <button className="delete-button" onClick={() => handleDelete(val._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Fixed buttons at the bottom */}
      <div className="fixed-buttons">
        <button className="left-button" onClick={SendSMS}>SMS Invoice</button>
        <button className="right-button" onClick={formbuttonn}>Invoice</button>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  console.log(state.EmployeeDetailsReducer.Employee, "employeeeee$");
  return {
    payementHistory: state.PayementHistoryReducer,
    EmployeeDetails: state.EmployeeDetailsReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {

    fetchEmployeeDetailsSuccess: (value) => dispatch(fetchEmployeeDetailsSuccess(value)),
    fetchEmployeeDetailsFailure: (value) => dispatch(fetchEmployeeDetailsFailure(value)),


    fetchPayementHistorySuccess: (value) => dispatch(fetchPayementHistorySuccess(value)),
    fetchPayementHistoryFailure: (value) => dispatch(fetchPayementHistoryFailure(value)),



  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCreate);

