// import * as React from "react";
// import { Routes, Route, Router } from "react-router-dom";
// import "./App.css";
// import Navbar from "./components/Navbar";
// import Dashboard from "./components/Dashboard";
// import Login from "./components/Login";
// import Table from "./components/Table";
// import UserPermision from "./components/UserPermision";

// import FormPageee from "./components/FormPage";
// import InvoiceCreate from "./components/invoiceCreate";
// import { DataProvider } from "./components/DataContext";

// function App() {
//   return (
//     <>
//       <div className="App">
//         {/* <BrowserRouter >
//           <Routes>
//             <Route path="/" element={<Login />} />
//             <Route path="/dashboard" exact element={<Dashboard />} />
//             <Route path="/navbar" element={<Navbar />} />
//             <Route path="/table" element={<Table />} />
//             <Route path="/invoiceCreate/:shortId" element={<InvoiceCreate />} />
//             <Route path="/UsersandPermissions" element={<UserPermision />} />
           

//           </Routes>
//         </BrowserRouter> */}
//         <Router>
//       <DataProvider>
//         <Routes>
//         <Route path="/" element={<Login />} />
//             <Route path="/dashboard" exact element={<Dashboard />} />
//             <Route path="/navbar" element={<Navbar />} />
//             <Route path="/table" element={<Table />} />
//             <Route path="/invoiceCreate/:shortId" element={<InvoiceCreate />} />
//             <Route path="/UsersandPermissions" element={<UserPermision />} />
//         </Routes>
//       </DataProvider>
//     </Router>
//       </div>
//     </>
//   );
// }

// export default App;
import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Table from "./components/Table";
import UserPermision from "./components/UserPermision";
import InvoiceCreate from "./components/invoiceCreate";
import { DataProvider } from "./components/DataContext";
import ForgetPassword from "./components/ForgetPassword";
import Reports from "./components/Reports";

function App() {
  return (
    <Router>
      <DataProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/navbar" element={<Navbar />} />
          <Route path="/table" element={<Table />} />
          <Route path="/invoiceCreate/:shortId" element={<InvoiceCreate />} />
          <Route path="/UsersandPermissions" element={<UserPermision />} />
          <Route path="/forget" element={<ForgetPassword />} />
          <Route path="/reports" element={<Reports />} />
        </Routes>
      </DataProvider>
    </Router>
  );
}

export default App;
