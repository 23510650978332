// import { combineReducers } from "redux";
// import loginReducer from "./loginReducer";
// const rootReducer = combineReducers({
//     loginReducer
// })

// export default rootReducer;

import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import fetchBookingReducer from './fetchBookingReducer';
import fetchSlotReducer from './fetchSlotReducer';
import fetchEmployeeReducer from './fetchEmployeeReducer';
import EmployeeDetailsReducer from './fetchEmployeeDetailsReducer';
import fetcheditPermissionReducer from './fetcheditPermissionReducer';
import fetchActivateReducer from './fetchActivateReducer';
import fetchCreateEmployeeReducer from './fetchCreateEmployeeReducer';
import editEmployeeReducer from './fetchEditEmployeeReducer';
import updatePasswordReducer from './fetchUpdatePasswordReducer';
import PayementHistoryReducer from './fetchPayementHistoryReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
    login: loginReducer, EmployeeDetailsReducer,  PayementHistoryReducer, updatePasswordReducer,fetchBookingReducer,fetchSlotReducer,fetchEmployeeReducer,fetcheditPermissionReducer,fetchActivateReducer,fetchCreateEmployeeReducer,editEmployeeReducer, userReducer
});

export default rootReducer;

