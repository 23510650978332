const isProduction = 1;

const PRODUCTION_ENDPOINT = "https://www.queuemate.com";
const STAGING_ENDPOINT = "http://3.19.185.123:8082";

export const ENDPOINT = isProduction ? PRODUCTION_ENDPOINT : STAGING_ENDPOINT;

export const Urls = {
    login: "login",
    getParticularSlotBooking: 'getParticularSlotBooking',
    getSlotDetails:"getDayDetails",
    getEmplyeelist:"v2/business/getEmployeeListBusiness",
    editPermissions:"editPermissions",
    activateDeactivateEmployee:"activateDeactivateEmployee",
    createEmployee:"createEmployee",
    editprofileEmployee:"updateProfile",
    updatePassword:"updatePassword",
    payementHistory:"getAllPayments",
    removePaymentId:"removePaymentId",
    getEmplyeeDetail:"getBookingDetails",
    getBookingDetail:"getBookingDetails",
    SMSInvoice:"SMSInvoice",
    addPayment:"addPayment"
}


