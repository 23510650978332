export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const FETCH_BOOKING_REQUEST = "FETCH_BOOKING_REQUEST";
export const FETCH_BOOKING_SUCCESS = "FETCH_BOOKING_SUCCESS";
export const FETCH_BOOKING_FAILURE = "FETCH_BOOKING_FAILURE";


export const FETCH_SLOT_REQUEST = "FETCH_SLOT_REQUEST";
export const FETCH_SLOT_SUCCESS = "FETCH_SLOT_SUCCESS";
export const FETCH_SLOT_FAILURE = "FETCH_SLOT_FAILURE";


export const FETCH_EMPLOYEE_REQUEST = "FETCH_EMPLOYEE_REQUEST";
export const FETCH_EMPLOYEE_SUCCESS = "FETCH_EMPLOYEE_SUCCESS";
export const FETCH_EMPLOYEE_FAILURE = "FETCH_EMPLOYEE_FAILURE";

export const FETCH_EMPLOYEE_DETAILS_REQUEST = "FETCH_EMPLOYEE_DETAILS_REQUEST";
export const FETCH_EMPLOYEE_DETAILS_SUCCESS = "FETCH_EMPLOYEE_DETAILS_SUCCESS";
export const FETCH_EMPLOYEE_DETAILS_FAILURE = "FETCH_EMPLOYEE_DETAILS_FAILURE";



export const FETCH_PAYEMENT_HISTORY_REQUEST = "FETCH_PAYEMENT_HISTORY_REQUEST";
export const FETCH_PAYEMENT_HISTORY_SUCCESS = "FETCH_PAYEMENT_HISTORY_SUCCESS";
export const FETCH_PAYEMENT_HISTORY_FAILURE = "FETCH_PAYEMENT_HISTORY_FAILURE";


export const FETCH_EMPLOYEE_EDIT_REQUEST = "FETCH_EMPLOYEE_EDIT_REQUEST";
export const FETCH_EMPLOYEE_EDIT_SUCCESS = "FETCH_EMPLOYEE_EDIT_SUCCESS";
export const FETCH_EMPLOYEE_EDIT_FAILURE = "FETCH_EMPLOYEE_EDIT_FAILURE";

export const FETCH_UPDATE_PASSWORD_REQUEST = "FETCH_UPDATE_PASSWORD_REQUEST";
export const FETCH_UPDATE_PASSWORD_SUCCESS = "FETCH_UPDATE_PASSWORD_SUCCESS";
export const FETCH_UPDATE_PASSWORD_FAILURE = "FETCH_UPDATE_PASSWORD_FAILURE";

export const FETCH_EDITPERMISSION_REQUEST = "FETCH_EDITPERMISSION_REQUEST";
export const FETCH_EDITPERMISSION_SUCCESS = "FETCH_EDITPERMISSION_SUCCESS";
export const FETCH_EDITPERMISSION_FAILURE = "FETCH_EDITPERMISSION_FAILURE";

export const FETCH_ACTIVATE_REQUEST = "FETCH_ACTIVATE_REQUEST";
export const FETCH_ACTIVATE_SUCCESS = "FETCH_ACTIVATE_SUCCESS";
export const FETCH_ACTIVATE_FAILURE = "FETCH_ACTIVATE_FAILURE";

export const FETCH_CREATEEMPLOYEE_REQUEST = "FETCH_CREATEEMPLOYEE_REQUEST";
export const FETCH_CREATEEMPLOYEE_SUCCESS = "FETCH_CREATEEMPLOYEE_SUCCESS";
export const FETCH_CREATEEMPLOYEE_FAILURE = "FETCH_CREATEEMPLOYEE_FAILURE";