import React from "react";
import "./Dashboard.css";
import Navbar from "../components/Navbar";
import Table from "../components/Table";
import { useMediaQuery } from "react-responsive";
function Dashboard(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  console.log(props, "pppp")
  return (
    <div className="hello">
       <Navbar title="Home" />
      {/* {isMobile ? (
        <div>
          <Navbar />
        </div>
      ) : (
        <div>
          <Navbar />
        </div>
      )} */}

      <Table />
    </div>
  );
}

export default Dashboard;
