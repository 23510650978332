import React from 'react';
import './UserSwitchPopup.css'; // Import CSS for styling
import { connect, useDispatch } from 'react-redux';
import { fetchEmployee, setCurrentUser } from '../redux/actions/userAction';

const UserSwitchPopup = ({ onClose, user, setCurrentUser }) => {
    console.log('User:', user);
    const dispatch = useDispatch();

    const handleUserSelect = (userData) => {
        // Update currentUser in Redux state
        setCurrentUser(userData);
        localStorage.setItem("current-user", JSON.stringify(userData)); 

        // Update localStorage values
        localStorage.setItem("empId", userData.employee);
        localStorage.setItem("bAddress", userData.bAddress._id ); 
        localStorage.setItem("bid", userData.business);

        dispatch(fetchEmployee());

        // Close the popup after user selection
        onClose();
    };

    const renderUserOptions = () => {
        return user.map((userData, index) => (
            <div key={index} style={{ display: 'flex', gap: '20px', cursor: 'pointer' }} onClick={() => handleUserSelect(userData)}>
                <img style={{ width: '100px', height: '100px' }} src={userData.bAddress.logo} alt={userData.company.companyName} className="user-logo" />
                <div style={{ alignSelf: 'center', width: '64%', textAlign: 'center' }}><h3>{userData.bAddress.name}</h3></div>
            </div>
        ));
    };
    return (
        <div className="popup-container">
            <div className="backdrop"></div> {/* Backdrop covering entire screen */}
            <div className="popup-content">
                {/* Popup content */}
                <div className="popup-header">Switch User</div>
                <div className="user-options">
                    {/* Render user options */}
                    {renderUserOptions()}
                </div>
                <div style={{textAlign: 'center'}}>
                <button className="close-btn" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.login.user.Employee.businessDetails, // Assuming the login data is stored in the 'user' field of the 'login' reducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentUser: (userData) => dispatch(setCurrentUser(userData)), // Action creator to update current user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSwitchPopup);
