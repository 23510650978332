import * as actionTypes from "../types/userTypes";

const initialState = {
    userData: null, // Load user data from localStorage if it exists
    loading: false,
    error: null,
  };

  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SET_CURRENT_USER:
        console.log(action.type, "qwq")
        return { ...state, userData: action.payload, loading: true, error: null };
      default:
        return state;
    }
  };

export default userReducer;