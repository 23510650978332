import * as actionTypes from "../types/userTypes";


const initialState = {
    slotdata: null,  // This will hold the fetched Slot data
    loading: false,  // Indicates if the request is in progress
    error: null,  // Holds any error messages
  };

  const SlotReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_SLOT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
        case actionTypes.FETCH_SLOT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };

    case actionTypes.FETCH_SLOT_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default SlotReducer;
