import React, { useState } from "react";
import "./Login.css";
import Icon from "./image/iconqueue.png";
import { connect, useDispatch } from 'react-redux';
import { login, loginFailure, loginSuccess } from '../redux/actions/userAction';
import isEmpty from "../validations/isEmpty";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";


function Login(props) {
  const dispatch = useDispatch();
  const { user } = props

  console.log(user, "data")

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e, type) => {
    e.preventDefault();

    if (!isEmpty(e)) {
      // alert("Please fill in all fields");
      let dto = { phoneNumber, password };
      dto[type] = e;
      login(dto);
    }

    else if (!phoneNumber || !password) {
      toast.error("Please fill in all fields");
      return;
    }

    const dto = {
      phoneNumber,
      password,
    };

    dispatch(login(dto));

  };


  return (
    <div className="background-image">
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="text-center" style={{ width: "375px", height: '565px', position: 'absolute', background: '#08BEBC4D', display: 'flex', justifyContent: 'space-evenly' }}>

        <ToastContainer />
          <div className="form-details">
            <div>
              <img src={Icon} alt="QueueMate" className="mt-5 image-icon" />
              <h2 className="my-3 login-heading">Login</h2>
            </div>
            <form name="forgotForm" onSubmit={handleSubmit}>
              <div className="form-outline" style={{ textAlign: "start" }}>

                {/* <input class="form-control" type="number" id="phoneNumber" placeholder="Phone Number" required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)} /> */}

                <input
                  className="form-control centered-placeholder"
                  type="tel"
                  id="phoneNumber"
                  inputMode="numeric"
                  placeholder="Phone Number"
                  pattern="[0-9]*"
                  required
                  style={{ textAlign: 'center', textAalignLast: 'center' }}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/, ''))}
                />

              </div>
              <br />
              <div className="form-outline mb-4" style={{ textAlign: "start" }}>
                <input
                  required
                  type="password"
                  id="password"
                  placeholder="Password"
                  className="form-control centered-placeholder"
                  style={{ textAlign: 'center', textAalignLast: 'center' }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="row mb-4">
                <div className="col d-flex justify-content-center"></div>
              </div>

              {/* {error && <div className="text-danger">{error}</div>} */}

              <Link style={{ color: 'white', textDecoration: 'none' }} to="/forget"><h4>Forgot Password?</h4></Link>

              <button
                type="submit"
                style={{
                  backgroundColor: "#08BEBC",
                  border: "1px solid transparent",
                  fontFamily: 'sans-serif',
                  fontSize: '24px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'center'
                }}
                className="w-100 btn btn-primary btn-block mb-4"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
}

const mapStateToProps = (state) => {
  console.log(state, "stat")
  return {
    user: state.loginReducer,
    error: state.loginReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (value) => dispatch(loginSuccess(value)),
    loginFailure: (value) => dispatch(loginFailure(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
