import React from 'react'
import './ForgetPassword.css'
import Icon from '../components/image/Homeicon.svg'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ENDPOINT } from '../network/Urls';


function ForgetPassword() {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSendOTP = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        // Extracting country code and phone number
        const parsedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        const extractedCountryCode = '+' + parsedPhoneNumber.substring(0, 2); // Assuming country code is 2 digits
        const extractedPhoneNumber = parsedPhoneNumber.substring(2); // Removing country code

        const raw = JSON.stringify({
            "phoneNumber": extractedPhoneNumber,
            "countryCode": extractedCountryCode
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${ENDPOINT}/v2/user/sendOTP`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.Success === 1) {
                    console.log("OTP sent successfully");
                    setOtpSent(true);
                    setErrorMessage("")
                } else {
                    console.log("Failed to send OTP:", result.Message);
                    setErrorMessage(result.Message);
                }
            })
            .catch(error => {
                console.log('Error:', error);
                setErrorMessage("Failed to send OTP. Please try again later.");
            });
    };

    const handleVerifyAndSave = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        const parsedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        const extractedCountryCode = '+' + parsedPhoneNumber.substring(0, 2); // Assuming country code is 2 digits
        const extractedPhoneNumber = parsedPhoneNumber.substring(2); // Removing country code

        const raw = JSON.stringify({
            "phoneNumber": extractedPhoneNumber,
            "countryCode": extractedCountryCode,
            "otp": otp,
            "newPassword": newPassword
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://www.queuemate.com/v2/user/verifyOTPSavePassword", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.Success === 1) {
                    console.log("Password updated");
                    toast.success(result.Message, { autoClose: 3000 });
                    setSuccessMessage(result.Message);
                } else {
                    console.log("Failed to update password:", result.Message);
                    toast.error(result.Message, { autoClose: 3000 });

                }
            })
            .catch(error => {
                console.log('Error:', error);
                toast.error("An error occurred. Please try again later.", { autoClose: 3000 });
            });
    };


    return (
        <div>
            <nav className='heading-forget'>
                <h3 className='title-forget'>
                    Forget Password
                </h3>
            </nav>

            <div className='container'>
                <div className='row' style={{ paddingTop: '60px', display: 'grid', justifyContent: 'center', textAlign: 'center', gap: '50px' }}>
                    <div className='col' style={{ display: 'flex', justifyContent: 'center' }}>
                        <img className='icon-forget'
                            src={Icon}
                            alt="Icon"
                        />
                    </div>
                    {successMessage ? (
                        <div style={{ marginTop: '20px', textAlign: 'center', backgroundColor: 'white', padding: '20px' }}>
                            <p>{successMessage}</p>
                            <button className='forget-button' onClick={() => window.location.href = "/"}>Login</button>
                        </div>
                    ) :
                        (
                            <>
                                <div className='col'>
                                    {otpSent ? (

                                        <input className="form-control centered-placeholder"
                                            type="tel"
                                            placeholder="Enter OTP"
                                            value={otp}
                                            onChange={(e) => {
                                                const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                                if (input.length <= 6) {
                                                    setOtp(input); // Set the state only if it's within the limit
                                                }
                                            }}
                                            pattern="[0-9]*"
                                            maxLength={6}
                                            required
                                            style={{ textAlign: 'center', textAalignLast: 'center' }}
                                        />

                                    ) : (
                                        <PhoneInput
                                            country={'in'}
                                            value={phoneNumber}
                                            onChange={setPhoneNumber}
                                        />
                                    )}
                                </div>
                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                <div>
                                    {otpSent ? (
                                        <input className="form-control centered-placeholder" style={{ textAlign: 'center', textAalignLast: 'center' }} type="password" placeholder="Enter New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                    ) : (
                                        <button className='forget-button' type="button" onClick={handleSendOTP}>Send OTP</button>
                                    )}
                                </div>
                                <div>
                                    {otpSent && <button className='forget-button' type="button" onClick={handleVerifyAndSave}>Verify and Save</button>}
                                </div>
                            </>
                        )
                    }

                </div>

            </div>

            {/* {successMessage && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', backgroundColor: 'white', padding: '20px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                    <p>{successMessage}</p>
                    <button onClick={() => window.location.href = "/"}>Go to Home</button>
                </div>
            )} */}

        </div>
    )
}

export default ForgetPassword