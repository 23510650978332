import React, { useEffect, useState, useRef } from "react";
import Navbar from "../components/Navbar";
import "./UserPermision.css"
import Pencil from "../components/image/pencil.svg";
import cancel from "../components/image/cancel.svg";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchActivateDeactivateEmployee,
  fetchActivateDeactivateEmployeeFailure,
  fetchActivateDeactivateEmployeeSuccess,
  fetchBooking,
  fetchBookingFailure,
  fetchBookingSuccess,
  fetchCreateEmployee,
  fetchCreateEmployeeFailure,
  fetchCreateEmployeeSuccess,
  fetchEditEmployee,
  fetchEditEmployeeFailure,
  fetchEditEmployeeRequest,
  fetchEditEmployeeSuccess,
  fetchEditPermissions,
  fetchEditPermissionsFailure,
  fetchEditPermissionsSuccess,
  fetchEmployee,
  fetchEmployeeFailure,
  fetchEmployeeSuccess,
  fetchSlot,
  fetchSlotFailure,
  fetchSlotSuccess,
  fetchUpdatePassword,
  fetchUpdatePasswordFailure,
  fetchUpdatePasswordSuccess,
} from "../redux/actions/userAction";
import './SignUpForm.css';
import { getUserDetails } from "./apiwrapper";
import { ENDPOINT, Urls } from "../network/Urls";
const optionMapping = {
  1: "CancelSlot",
  2: "CompletedSlot",
  3: "StartedSlot",
  4: "NotStartedSlot",
  5: "NoMoreBookingStatusSlot",
  6: "UpdateSlot",
  7: "BookingUpdate",
  8: "EditSlots",
  9: "ViewAdmin",
  10: "SendSMS",
  A: "Invoice",
  B: "SMSInvoice",
  C: "DownloadInvoice",
  D: "AddPayment",
};


function UserPermission(props) {
  const dispatch = useDispatch();
  const { empdata, updatepassword } = props
  const EmployeeArray = empdata?.data?.Data || [];

  const [isModalOpen, setModalOpen] = useState(false);


  const openModal = () => {
    setModalOpen(true);

  };
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const togglePasswordFields = () => {
    setShowPasswordFields(!showPasswordFields);
  };
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [selectedUserid, setSelectedUserid] = useState(null);
  const [selectedUserBussid, setSelectedUserBussid] = useState(null);
  const dropdownRef = useRef(null);
  const openModal2 = (data) => {
    setSelectedUserName(data.name);
    setSelectedUserid(data._id)
    setSelectedUserBussid(data.employee._id)
    const optionsString = data.permissions || ''; // Default to an empty string if permissions are undefined
    const uniqueOptionsArray = [...new Set(optionsString.split('$').filter(Boolean))]; // Split and filter out empty values

    const updatedOptions = uniqueOptionsArray.join(',');

    // alert(`Selected Options: ${updatedOptions}`);
    setSelectedOptions(updatedOptions)

    setModalOpen2(true);
  };

  const closeModal2 = () => {
    dispatch(fetchEmployee());
    setModalOpen2(false);
  };

  const [isModalOpen3, setModalOpen3] = useState(false);
  const setFormDataFromData = (data) => {
    setFormData({
      fullName: data.name || '',
    });
  };

  const openModal3 = (data) => {
    setFormDataFromData(data)
    setSelectedUserid(data._id)
    setModalOpen3(true);
  };


  const closeModal3 = () => {
    setModalOpen3(false);
    resetFormData(); // Call a function to reset form data
    dispatch(fetchEmployee());
  };

  const resetFormData = () => {
    setFormData({
      fullName: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
    setFormErrors({
      fullName: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
  };
  const validateFormDetails = () => {
    const errors = {};

    if (!formData.fullName.trim()) {
      errors.fullName = 'Full Name is required';
    }

    // Check if formData.password exists before accessing its length property
    if (formData.password && formData.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }

    // if (formData.password !== formData.confirmPassword) {
    //   errors.confirmPassword = 'Passwords do not match';
    // }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const storedBAddress = localStorage.getItem("bAddress");
  const storedid = localStorage.getItem("bid");
  const storedempId = localStorage.getItem("empId");

  const handleSubmitDetails = (e) => {
    e.preventDefault();

    const isValid = validateFormDetails();
    if (showPasswordFields) {
      // Save password logic
      if (isValid) {

        const requestData1 = {

          employeeId: selectedUserid,
          password: formData.password,
          newPassword: formData.confirmPassword

        };


        UpdatePassword(requestData1);
        closeModal3()
      }
    } else {
      // Save name logic
      if (isValid) {
        const requestData4 = {
          employeeId: selectedUserid,
          data: {
            name: formData.fullName
          }
        }

        dispatch(fetchEditEmployee(requestData4));
        closeModal3()
      }
    }
  };
  const UpdatePassword = (requestData) => {

    let token = localStorage.getItem("user-info");

    // Define your headers and request data here
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("timeZone", "Asia/Calcutta");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("permissions", "1$2$3$4$5$6$7$8$9$10$A$B$C$D");
    // Make the API request
    fetch(`${ENDPOINT}/${Urls.updatePassword}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle a successful response
        alert(data.Message)
        console.log("      edit ", data)
      })
      .catch((error) => {
        // Handle errors

      });
  };
  const [selectedOptions, setSelectedOptions] = useState('1,4,5,D,A');

  const optionsArray = selectedOptions.split(',');

  const handleOptionSelect = (key) => {
    setSelectedOptions((prevOptions) => {
      const updatedOptions = optionsArray.includes(key)
        ? optionsArray.filter((selectedKey) => selectedKey !== key).join(',')
        : [...optionsArray, key].join(',');

      console.log("Selected keys:", updatedOptions);

      return updatedOptions;
    });
  };






  const handleSaveOptions = () => {
    const optionsArray = selectedOptions.split(',').map(option => option.trim());
    const formattedPermissions = optionsArray
      .map(item => `$${item.trim()}`)
      .filter(item => item !== '$'); // Exclude empty $

    const updatedRequestData = {
      employeeId: selectedUserid,
      employeebusinessId: selectedUserBussid,
      toBeRemoved: false,
      permissions: formattedPermissions,
    };

    console.log(updatedRequestData, 'Updated Request Data');
    // alert(`Selected Optionsss: ${optionsArray.join(", ")}`);
    closeModal2()
    dispatch(fetchEditPermissions(updatedRequestData));
    dispatch(fetchEmployee());
  };




  const initialFormData = {
    fullName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  const [formErrors, setFormErrors] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const validateForm = () => {
    const errors = {};

    if (!formData.fullName.trim()) {
      errors.fullName = 'Full Name is required';
    }

    const phoneRegex = /^\d{10}$/; // Basic phone number validation (10 digits)
    if (!formData.phoneNumber.trim() || !phoneRegex.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Valid phone number is required';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      errors.email = 'Valid email is required';
    }

    if (formData.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    const isValid = validateForm();

    if (isValid) {
      console.log('Form submitted:', formData);

      // Check if formData is not empty
      if (Object.keys(formData).length !== 0) {
        const requestData2 = {
          employee: {
            name: formData.fullName,
            email: formData.email,
            password: formData.password,
            phoneNumber: formData.phoneNumber,
          },
          businessData: {
            address: storedBAddress,
            business: storedid,
          },
        };

        dispatch(fetchCreateEmployee(requestData2));
        dispatch(fetchEmployee());
        closeModal();
      } else {
        console.log('Form data is empty');
      }
    } else {
      console.log('Form has validation errors');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  };


  const closeModal = () => {
    setModalOpen(false);
    setFormData(initialFormData);
    setFormErrors({
      fullName: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
  };
  const [dropdownOpen, setDropdownOpen] = useState(new Array(EmployeeArray.length).fill(false));
  const closeDropdown = () => {
    setDropdownOpen([]);

  };
  const toggleDropdown = (index) => {
    const newIsDropdownOpenArray = [...dropdownOpen];
    newIsDropdownOpenArray[index] = !newIsDropdownOpenArray[index];
    setDropdownOpen(newIsDropdownOpenArray);
  };
  let token = localStorage.getItem("user-info");

  // Define your headers and request data here
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("timeZone", "Asia/Calcutta");
  myHeaders.append("Content-Type", "application/json");








  useEffect(() => {
    // Dispatch your actions or perform any other setup as needed fetchUpdatePassword

    dispatch(fetchEmployee());
    console.warn(updatepassword, "pppp")
    // const handleClickOutside = (event) => {
    //   // Check if the clicked element is inside the dropdown or not
    //   if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('dropdown-item')) {
    //     closeDropdown();
    //   }
    // };

    // // Add event listener when the component mounts
    // document.addEventListener('mousedown', handleClickOutside);



    // ... rest of your code ...
  }, []);

  const deactivateAccount = (id) => {
    const isConfirmed = window.confirm('Are you sure you want to deactivate this account?');
    const requestData1 = {

      employeeId: id,
      activate: false

    };
    if (isConfirmed) {
      // Perform the deactivation logic here
      // For example, you might make an API call to deactivate the account
      dispatch(fetchActivateDeactivateEmployee(requestData1));
      console.log('Account deactivated', id);
      dispatch(fetchEmployee());
      dispatch(fetchEmployee());
    } else {
      // User clicked "Cancel" in the alert box
      console.log('Deactivation canceled');
    }
  };
  const activateAccount = (id) => {
    const isConfirmed = window.confirm('Are you sure you want to activate this account?');
    const requestData0 = {

      employeeId: id,
      activate: true

    };
    if (isConfirmed) {
      // Perform the deactivation logic here
      dispatch(fetchActivateDeactivateEmployee(requestData0));
      // For example, you might make an API call to deactivate the account
      console.log('Account activated', id);
      dispatch(fetchEmployee());
      dispatch(fetchEmployee());
    } else {
      // User clicked "Cancel" in the alert box
      console.log('activation canceled');
    }
  };
  return (

    <>
      <div className="main-content">
        <Navbar title="Users & Permissions" />
        <div className="corner-text">
          <h2>Users & Permissions</h2>
        </div>
        <div className="mainBottom">
          <div className="bottom-text">
            <div className="corner-right" onClick={openModal}>
              <h4>Add Staff</h4>
            </div>
            <div className="usertable">

              {EmployeeArray.length > 0 ?
                EmployeeArray.map((data, index) => (
                  <div className={`container d-flex align-items-start ${!data.isActive ? 'inactive-user' : ''}`} key={index}>
                    <div className="row mt-4" style={{ width: "100%", gap: "5px" }}>
                      <div className="col">
                        <div className="d-flex flex-column align-items-start">
                          <div className="user-names" >
                            <span style={{ color: !data.isActive ? '#D2D2D2' : 'black' }}>
                              {data.employee.name ? data.employee.name : 'Unknown Name'}
                            </span>

                          </div>
                          <div
                            className={`permission ${!data.isActive ? 'inactive-permission' : ''}`}
                            onClick={() => data.isActive ? openModal2(data) : activateAccount(data._id)}
                          >
                            <span style={{ color: !data.isActive ? '#D2D2D2' : '#08BEBC' }}>
                              {data.isActive ? (
                                data.employee && data.permissions ? (
                                  data.permissions.split('$').filter(Boolean).length < 14 ? (
                                    'Limited Permissions'
                                  ) : (
                                    'All Permissions'
                                  )
                                ) : (
                                  'No Permissions'
                                )
                              ) : (
                                'Inactive - Tap to Reactivate'
                              )}
                            </span>



                          </div>
                        </div>
                      </div>
                      <div className="col col-lg-2" style={{ alignSelf: "center" }}>
                        <div className="dropdown-container" ref={dropdownRef}>
                          <button className="pencil" onClick={() => toggleDropdown(index)}>
                            <img src={Pencil} alt="pencil" className="pencil-button" />
                          </button>
                          {dropdownOpen[index] && (
                            <div className="overlay">
                              <div className="dropdown-content">
                                <a href="#" onClick={() => { openModal2(data); closeDropdown(); }}>View Permissions</a>
                                <a href="#" onClick={() => { openModal3(data); closeDropdown(); }}>View/Edit Details</a>
                                <a href="#" onClick={() => { deactivateAccount(data._id); closeDropdown(); }}>Delete User</a>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : <div style={{ justifyContent: 'center', display: "flex", height: "250px", alignItems: "center" }}>
                  <h2>No booking found</h2>
                </div>}
            </div>
          </div>
        </div>
        {/* Your main content goes here */}
      </div>
      {/* Custom Modal */}
      {isModalOpen2 && (
        <div className="custom-modal2">
          <div className="modal-content2">
            <div className="close-icon" onClick={closeModal2}>
              <img src={cancel} alt="Close Icon" />
            </div>
            <h2 style={{
              fontFamily: 'Inter',
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '0em',
              position: 'relative',
              bottom: "25px"
            }}>{selectedUserName} - Permissions</h2>
            <div className="options-container custom-options-container">
              {Object.keys(optionMapping).map((key) => (
                <label key={key}>
                  {key}: {optionMapping[key]}
                  <input
                    type="checkbox"
                    checked={optionsArray.includes(key)}
                    onChange={() => handleOptionSelect(key)}
                  />
                </label>
              ))}
            </div>



            <button type="save" onClick={handleSaveOptions}>Save</button>

          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="custom-modal">
          <div className="modal-content">
            <div className="close-icon3" onClick={closeModal}>
              <img src={cancel} alt="Close Icon" />
            </div>

            <form className="signup-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  placeholder=" "
                />
                <label htmlFor="fullName" className={formData.fullName ? 'active' : ''}>
                  Full Name
                </label>
                <div className="error">{formErrors.fullName}</div>
              </div>

              <div className="form-group">
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  placeholder=" "
                />
                <label htmlFor="phoneNumber" className={formData.phoneNumber ? 'active' : ''}>
                  Phone Number
                </label>
                <div className="error">{formErrors.phoneNumber}</div>
              </div>

              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder=" "
                />
                <label htmlFor="email" className={formData.email ? 'active' : ''}>
                  Email
                </label>
                <div className="error">{formErrors.email}</div>
              </div>

              <div className="form-group">
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder=" "
                />
                <label htmlFor="password" className={formData.password ? 'active' : ''}>
                  Password
                </label>
                <div className="error">{formErrors.password}</div>
              </div>

              <div className="form-group">
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder=" "
                />
                <label htmlFor="confirmPassword" className={formData.confirmPassword ? 'active' : ''}>
                  Confirm Password
                </label>
                <div className="error">{formErrors.confirmPassword}</div>
              </div>
              <button className="save" type="submit">Save</button>
              <button className="cancel" type="button" onClick={closeModal}>Cancel</button>

            </form>


          </div>
        </div>
      )}
      {isModalOpen3 && (
        <div className="custom-modal3">
          <div className="modal-content3" style={{ height: showPasswordFields ? '420px' : '333px' }}>

            <div className="close-icon2" onClick={closeModal3}>
              <img src={cancel} alt="Close Icon" />
            </div>

            <form className="password-form" onSubmit={handleSubmitDetails}>
              {showPasswordFields ? (
                <>
                  <div className="form-group">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder=" "
                    />
                    <label htmlFor="password" className={formData.password ? 'active' : ''}>
                      Old Password
                    </label>
                    <div className="error">{formErrors.password}</div>
                  </div>

                  <div className="form-group">
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      placeholder=" "
                    />
                    <label htmlFor="confirmPassword" className={formData.confirmPassword ? 'active' : ''}>
                      New Password
                    </label>
                    <div className="error">{formErrors.confirmPassword}</div>
                    <div onClick={togglePasswordFields} style={{
                      cursor: 'pointer',
                      textAlign: 'right',   // Align to the right
                      color: '#08BEBC',        // Blue color
                      fontSize: '22px',     // Font size 25px
                      fontFamily: 'Montserrat',  // Font family Montserrat
                      top: "10px"
                    }}>
                      Update Employee
                    </div>
                  </div>
                </>
              ) : (
                <div className="form-group">
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label htmlFor="fullName" className={formData.fullName ? 'active' : ''}>
                    Full Name
                  </label>
                  <div className="error">{formErrors.fullName}</div>
                  <div
                    onClick={togglePasswordFields}
                    style={{
                      cursor: 'pointer',
                      textAlign: 'right',   // Align to the right
                      color: '#08BEBC',        // Blue color
                      fontSize: '22px',     // Font size 25px
                      fontFamily: 'Montserrat',  // Font family Montserrat
                      top: "10px"
                    }}
                  >
                    Change Password
                  </div>

                </div>


              )}

              <button type="submit">Save</button>
            </form>

            <button type="cancel" onClick={closeModal3}>Cancel</button>
          </div>
        </div>
      )}
    </>

  );
}

const mapStateToProps = (state) => {
  console.log(state.updatePasswordReducer.data, "statee");
  return {
    empdata: state.fetchEmployeeReducer,
    updatepassword: state.updatePasswordReducer.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    fetchActivateDeactivateEmployeeSuccess: (value) => dispatch(fetchActivateDeactivateEmployeeSuccess(value)),
    fetchActivateDeactivateEmployeeFailure: (value) => dispatch(fetchActivateDeactivateEmployeeFailure(value)),

    fetchEmployeeSuccess: (value) => dispatch(fetchEmployeeSuccess(value)),
    fetchEmployeeFailure: (value) => dispatch(fetchEmployeeFailure(value)),

    fetchEditPermissionsSuccess: (value) => dispatch(fetchEditPermissionsSuccess(value)),
    fetchEditPermissionsFailure: (value) => dispatch(fetchEditPermissionsFailure(value)),

    fetchEditEmployeeSuccess: (value) => dispatch(fetchEditEmployeeSuccess(value)),
    fetchEditEmployeeFailure: (value) => dispatch(fetchEditEmployeeFailure(value)),

    fetchUpdatePasswordSuccess: (value) => dispatch(fetchUpdatePasswordSuccess(value)),
    fetchUpdatePasswordFailure: (value) => dispatch(fetchUpdatePasswordFailure(value)),




    fetchCreateEmployeeSuccess: (value) => dispatch(fetchCreateEmployeeSuccess(value)),
    fetchCreateEmployeeFailure: (value) => dispatch(fetchCreateEmployeeFailure(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPermission);
